<template>
  <div
    v-if="usersTaskLoading"
    class="column items-center justify-center"
    style="height: calc(100vh - 44px); background: #f9fafc"
  >
    <q-circular-progress
      indeterminate
      size="75px"
      :thickness="0.1"
      color="primary"
    />
  </div>
  <div
    @click.stop="$emit('closeNotificaiton')"
    class="column overflow-auto relative-position"
    style="height: calc(100vh - 84px); background: #f9fafc; flex-wrap: nowrap"
    v-else
  >
  <div
        v-if="user.onboarding.is_mywork"
        class="empty-timeline"
        style="margin-bottom:0"
      >
        <div class="demo" style="margin-bottom:16px">
          <div class="tune-icon">
            <q-icon
              :name="$icons.matCheck"
              size="15px"
              style="color: #15d89a; background-color: white; border-radius: 5px"
            />
          </div>
          <div class="video">
            <div class="thumbnail" @click="videoPlayer">
              <q-icon :name="$icons.matPlayArrow" color="primary" size="xl" />
            </div>
          </div>
          <div class="text-contents">
            <span class="text-bold" style="margin-left:31px"
              >All tasks assigned to you across workspaces</span
            >
            <p>
              Here you can see all the tasks that are assigned to you from the
              workspaces you are part of.
            </p>
            <div
              style="width:50px; margin-left:31px; margin-top:10px"
              class="d-flex"
            >
              <!-- <q-btn
                dense
                no-caps
                class=" primary-button"
                color="#15d89a"
                size="md"
                label="Learn more"
                @click="$router.push({ name: 'LearnView' })"
              /> -->
              <q-btn
                dense
                no-caps
                style="margin-left:10px; height: 32px; border: solid 1px #677484;"
                size="md"
                label="Dismiss"
                @click="closeWalkthroughBanner"
              />
            </div>
          </div>
          <div class="close-icon" @click="closeWalkthroughBanner">
            <q-icon :name="$icons.matClose" size="22px" />
          </div>
        </div>
        <video-dialog
          v-if="showVideoModel"
          v-model="showVideoModel"
          :resetVideoDialog="resetVideoDialog"
          :videoMediaData="videoMediaData"
          :displayPlayer="displayPlayer"
        ></video-dialog>
      </div>
    <div class="my-work-admin-view overflow-auto">
      <div class="workload-box">
        <div class="workload-text">Workload</div>
        <div class="q-mt-md workload-progress row flex-no-wrap">
          <template v-for="(userWorkload, index) in usersWorkLoad">
            <div
              :key="userWorkload.id"
              :class="[
                'q-mr-md row items-center',
                userWorkload.length - 1 === index && 'q-pr-md',
              ]"
            >
              <member-tasks-progress
                :userWorkload="userWorkload"
                :myWorkDateFilter="myWorkDateFilter"
              />
            </div>
          </template>
        </div>
      </div>
      <div class="row q-mt-md members-card-wrapper">
        <template v-for="member in filteredMembers">
          <member-card
            :key="member.id"
            :member="member"
            :user="user"
            :myWorkDateFilter="myWorkDateFilter"
            :editTaskDialogHandler="openTaskDailog"
          />
        </template>
      </div>
    </div>
    <button @click="addTaskSection = true" class="fab-btn" style="margin-bottom: 16px">
      <q-icon :name="$icons.mdiPlusThick" size="20px" color="white" />
      <q-tooltip>New task</q-tooltip>
      <!-- <q-menu
        content-style="width:150px;max-height:200px;box-shadow:0px 2px 10px 0px rgb(0 0 0 / 19%)"
        v-model="fabMenu"
        auto-close
        :offset="[0, 0]"
      >
        <q-list class="q-py-sm">
          <q-item
            @click="addTaskSection = true"
            :class="['items-center q-px-lg']"
            clickable
            style="font-size: 15px"
          >
            <q-icon
              :name="$icons.fasCheck"
              size="18px"
              style="margin-right: 20px"
            />
            New task
          </q-item>
        </q-list>
      </q-menu> -->
    </button>
    <q-dialog
      transition-hide="none"
      transition-show="none"
      v-model="taskDialog"
      persistent
      @before-hide="closeDialog"
      @keydown.esc="resetTaskDialog"
    >
      <task-dialog
        v-if="editTaskdata"
        :tasks="editTaskdata"
        :reactions="reactions"
        :boardId="workspace.boards[0].id"
        :workspaceid="workspace.id"
        :workspace="workspace"
        :isWorkspaceOwner="isWorkspaceOwner"
        :currentUser="user"
        :authToken="currentCompany.accessToken"
        :workspaceMembers="workspaceMembers"
        :taskLabels="taskLabels"
        :openSubtask="openSubtask"
        :openTaskComment="openTaskComment"
        :currentCompany="currentCompany"
        :attachTaskMediaHandler="attachTaskMedia"
        :onUpdateTaskMembersHandler="reactivityOnUpdateTaskMembers"
        :deleteTaskCommentHandler="deleteTaskComment"
        :detachTaskAttachmentHandler="detachTaskAttachment"
        @reactOnPost="reactOnPost"
        @updateMediaCache="updateMediaCache"
        @createSubTask="createSubTask"
        @close="resetTaskDialog"
        @success="taskDialogSuccessHandler"
        @clearSubtasks="clearSubtasks"
        @createComment="createComment"
        @clickonmoreoption="clickonmoreoption"
        @deleteCommentPoint="deleteCommentPoint"
        @closeMediaModel="closeMediaModel"
        @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
        @updateComment="updateComment"
        @sendComment="sendComment"
        @deleteMediaComment="deleteMediaComment"
        @updatepositions="updatepositions"
        @addTaskAttchmentToMedia="addTaskAttchmentToMedia"
        @updateMediaCacheWithReactivity="updateMediaCacheWithReactivity"
        :openSubtaskDialog="openSubtaskDialog"
      ></task-dialog>
    </q-dialog>
    <q-dialog
        transition-hide="none"
        transition-show="none"
        persistent
        v-model="subTaskDialog"
        @before-hide="closeSubTaskDialog"
      >
        <sub-task-dialog
          v-if="currentSubTask"
          :tasks="currentTask"
          :subTask="currentSubTask"
          :taskLabels="taskLabels"
          :workspace="workspace"
          :workspaceid="workspace.id"
          :workspaceMembers="workspaceMembers"
          :currentCompany="currentCompany"
          :authToken="currentCompany.accessToken"
          :reactions="reactions"
          @createComment="createComment"
          :openTaskComment="openTaskComment"
          :deleteTaskCommentHandler="deleteTaskComment"
          @deleteCommentPoint="deleteCommentPoint"
          @updateMediaCommentPointLabel="updateMediaCommentPointLabel"
          @updateComment="updateComment"
          @sendComment="sendComment"
          @deleteMediaComment="deleteMediaComment"
          @clearSubtasks="clearChildSubtasks"
          :backToTask="backToTask"
          @createSubTask="createSubTask"
        ></sub-task-dialog>
      </q-dialog>
    <new-task-dialog
      v-if="addTaskSection"
      v-model="addTaskSection"
      :closeDialog="closeNewTaskDialog"
      :taskLabels="taskLabels"
      :userId="user.id"
      :showWorkspaceSection="true"
      sequencePosition="top"
    />
  </div>
</template>
<script>
import MediaMethodMixin from "@/mixins/MediaMethodMixin";
import BoardsMethodMixin from "@/mixins/BoardsMethodMixin";
import MemberCard from "@/views/MyWorkView/AdminView/MemberCard";
import MemberTasksProgress from "@/views/MyWorkView/AdminView/MemberTasksProgress";
import TaskDialog from "@/views/BoardView/TaskDialog";
import NewTaskDialog from "@/views/MyWorkView/NewTaskDialog.vue";
import SubTaskDialog from "@/views/BoardView/SubTaskDialog";
import VideoDialog from "@/components/VuePlayer/VideoDialog";

import has from "lodash/has";
import isEmpty from "lodash/isEmpty";

let meetWindow;

import {
  TaskQuery,
  SubtaskQuery,
  GenerateJwtTokenForUser,
  UpdateUserOnboardingMutation,
} from "@/gql";

export default {
  name: "AdminView",
  mixins: [BoardsMethodMixin, MediaMethodMixin],
  props: ["myWorkDateFilter", "myworkSearch", "toggleDrawerRight", "drawerRight"],
  components: {
    MemberCard,
    MemberTasksProgress,
    TaskDialog,
    NewTaskDialog,
    SubTaskDialog,
    VideoDialog,
  },
  api: {
    user: {
      cacheKey: "UserQuery",
      defaultValue: null,
    },
    usersTask: {
      cacheKey: "UsersTaskQuery",
      defaultValue: null,
    },
    reactions: {
      defaultValue: null,
      cacheKey: "ReactionsQuery",
    },
    taskLabels: {
      cacheKey: "TaskLabelsQuery",
      defaultValue: [],
    },
  },
  created() {
    const isAdmin =
      this.user && this.user.roles.find((o) => o.name === "admin");
    if (!isAdmin) {
      this.$router.push({
        name: "BoardView",
        params: { workspace: 1, company: this.$route.params.company },
      });
    }
    document.addEventListener("click", this.docClickEventHandler, false);
  },
  data() {
    return {
      displayPlayer: false,
      videoMediaData: null,
      showVideoModel: false,
      subTaskDialog: false,
      currentTask: null,
      currentSubTask: null,
      workspace: null,
      taskDialog: false,
      workspaceMembers: null,
      task: null,
      board: null,
      addTaskSection: false,
      fabMenu: false,
    };
  },
  methods: {
    async closeWalkthroughBanner() {
      this.user.onboarding.is_mywork = false;
      await this.$api.mutate({
        mutation: UpdateUserOnboardingMutation,
        variables: {
          flag_name: "is_mywork",
          flag_value: false,
        },
      });
    },
    videoPlayer() {
      this.videoMediaData = {
        file: "https://player.vimeo.com/video/585637023",
        isVimeo: true,
      };
      this.displayPlayer = true;
      this.showVideoModel = true;
    },
    resetVideoDialog() {
      this.displayPlayer = false;
      setTimeout(() => {
        this.showVideoModel = false;
      }, 10);
    },
    hasClass(elem, className) {
      return elem.className.split(" ").indexOf(className) > -1;
    },
    async docClickEventHandler(event) {
      const element = event.target;
      if (this.hasClass(element, "mention")) {
        event.preventDefault();
        const member = element.dataset;
        if (member && has(member, "id")) {
          const memberObject = this.workspaceMembers.find(
            (u) => u.username === member.id
          );
          if (memberObject) {
            this.showUserInfo(memberObject);
          }
        }
      }

      if (this.hasClass(element, "taskmention")) {
        event.preventDefault();
        const taskUrl = element.dataset;
        if (taskUrl && has(taskUrl, "id")) {
          let taskData = taskUrl.id.split("-");
          this.workspace = this.$api.getEntity(
            "workspace",
            taskData[1].replace(/\D/g, "")
          );
          // console.log("this.workspace", this.workspace, workspace);
          //if (workspace.id != this.workspace.id) {
            this.board = this.$api.getEntity(
              "board",
              this.workspace.boards[0].id
            );
          //}
          // const taskEntity = this.$api.getEntity("task", taskData[0]);

          if (taskData.length > 2) {
            this.openSubtaskDialog(taskData[0], taskData[2]);
          } else {
            let taskId = taskData[0].replace(/\D/g, "");
            this.openTask(taskId);
          }
        }
      }

      if (this.hasClass(element, "open-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          let userId = videoData.user;

          if (userId && userId == this.user.id) {
            let generateToken = await this.$api.mutate({
              mutation: GenerateJwtTokenForUser,
            });

            if (generateToken.data) {
              videoUrl += "?jwt=" + generateToken.data.generateJwtTokenForUser;
            }
          }

          meetWindow = window.open(
            videoUrl,
            "heycollabVideo",
            "width=600,height=600,scrollbars=yes,top=100,left=400,resizable = yes"
          );

          window.addEventListener("message", this.closeVideoCallDialog, false);
        }
      }

      if (this.hasClass(element, "copy-video-call")) {
        event.preventDefault();
        const videoData = element.dataset;

        if (videoData && has(videoData, "url")) {
          let videoUrl = videoData.url;
          this.$copyTextToClipboard(videoUrl);
        }
      }
    },
    closeVideoCallDialog(event) {
      if (
        !meetWindow ||
        typeof event.data.isCloseDialog == "undefined"
      ) {
        return false;
      }
      event.preventDefault();
      meetWindow.close();
      return true;
    },
    async openTask(taskId) {
      const task = this.$api.getEntity("task", taskId);
      let taskEntity = null;
      if (task) {
        taskEntity = task;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        taskEntity = taskQueryData.data.task;
      }

      if (taskEntity) {
        let isOpen = null;
        let task = taskEntity;
        let taskDetails = {
          task,
          isOpen,
        };
        this.editTask(taskDetails);
        this.taskDialog = true;
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async openSubtaskDialog(
      taskId,
      subtaskId,
      taskData = null,
      subtask = null
    ) {
      if (taskData) {
        this.currentTask = taskData;
      } else {
        let taskQueryData = await this.$api.query({
          query: TaskQuery,
          variables: {
            id: taskId,
          },
        });

        const taskEntity = taskQueryData.data.task;
        if (taskEntity) {
          this.currentTask = taskEntity;
        }
      }

      let subtaskEntity = null;
      if (subtask) {
        subtaskEntity = subtask;
      } else {
        let subtaskQueryData = await this.$api.query({
          query: SubtaskQuery,
          variables: {
            id: subtaskId,
          },
        });

        subtaskEntity = subtaskQueryData.data.subtask;
      }

      if (subtaskEntity) {
        this.subTaskDialog = true;
        this.currentSubTask = subtaskEntity;
        this.closeDialog();
        this.resetTaskDialog();
      } else {
        this.isTaskFound = true;
        this.$router.replace({ query: null });
      }
    },
    async backToTask(taskId) {
      await this.openTask(taskId);
      this.closeSubTaskDialog();
    },
    closeSubTaskDialog() {
      if (!isEmpty(this.$route.query)) {
        this.$router.replace({ query: null });
      }
      this.subTaskDialog = false;
      this.currentSubTask = null;
    },
    closeDialog() {
      this.$eventBus.$emit("closeTaskDialog");
    },
    closeNewTaskDialog() {
      this.addTaskSection = false;
    },
    async openTaskDailog(data) {
      if (data.task.myworks[0]) {
        this.workspace = this.$api.getEntity(
          "workspace",
          data.task.myworks[0].workspace_id
        );
        if (this.workspace) {
          this.board = this.$api.getEntity(
            "board",
            this.workspace.boards[0].id
          );
        }
        this.workspaceMembers = this.workspace.users;
      } else if (data.task.board_id) {
        this.board = this.$api.getEntity("board", data.task.board_id);
        this.workspace = this.$api.getEntity(
          "workspace",
          this.board.workspace_id
        );
        this.workspaceMembers = this.workspace.users;
      }
      this.editTask(data);
    },
  },
  computed: {
    usersWorkLoad() {
      return this.usersTask;
    },
    isWorkspaceOwner() {
      if (this.workspace && this.user) {
        return this.user.id === this.workspace.owner.id;
      }
      return false;
    },
    filteredMembers() {
      return this.myworkSearch.memberSearchText
        ? this.usersTask.filter(
            (o) =>
              o.fullname &&
              o.fullname
                .toLowerCase()
                .includes(this.myworkSearch.memberSearchText.toLowerCase())
          )
        : this.usersTask;
    },
    isAdmin() {
      return this.user && this.user.roles.find((o) => o.name === "admin");
    },
  },
  beforeDestroy() {
    document.removeEventListener("click", this.docClickEventHandler, false);
  },
};
</script>
<style lang="scss" scoped>
.fab-btn {
  position: absolute;
  bottom: 35px;
  right: 25px;
  width: 45px;
  height: 45px;
  background: #007aff;
  border-radius: 12px;
  /* box-shadow: 0 1px 5px #0003, 0 2px 2px #00000024, 0 3px 1px -2px #0000001f; */
  border: 3px solid #dbecff;
  z-index: 999;
}
</style>
